import React from 'react';
import PropTypes from 'prop-types';
import { SubHeadline, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import dataSelector from '~/shared/util/data-selector';
import Price from '~/shared/components/Price';
import { PRICE_FORMATS } from '~/shared/constants/price';

const wrapperStyles = css`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled('div')(wrapperStyles);

const titleStyles = ({ theme }) => css`
  font-weight: bold;
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.kilo};
`;

const Title = styled(SubHeadline)(titleStyles);

const calculatedValueLabelStyles = css`
  margin-bottom: 0;
`;

const CalculatedLabel = styled('span')(calculatedValueLabelStyles);

const payoutSubLabelStyles = ({ theme }) => css`
  color: var(--cui-fg-accent);
  margin-bottom: ${theme.spacings.mega};
`;

const PayoutSubLabel = styled(Body)(payoutSubLabelStyles);

const priceContainerStyles = () => css`
  margin-right: 24px;
`;

const PriceContainer = styled('div')(priceContainerStyles);

/**
 * Result for Pricing Calculator
 */
const Result = ({
  dataSelectorTarget,
  payoutLabel,
  payoutSublabel,
  currencySymbol,
  payout,
  fees,
  resultTitle,
  feeLabel,
  prependCurrencySymbol,
  hideFeeAmount,
}) => (
  <Wrapper>
    <Title as="h3">{resultTitle}</Title>
    <PriceContainer>
      <Price
        product={{
          price: payout,
          currency: currencySymbol,
          prependCurrencySymbol,
        }}
        format={PRICE_FORMATS.SIZE_32}
        dataSelector={dataSelector('amount_payout', dataSelectorTarget)}
      />
    </PriceContainer>
    <CalculatedLabel>{payoutLabel}</CalculatedLabel>
    <PayoutSubLabel variant="highlight">{payoutSublabel}</PayoutSubLabel>
    {hideFeeAmount ? null : (
      <>
        <Price
          product={{
            price: fees,
            currency: currencySymbol,
            prependCurrencySymbol,
          }}
          dataSelector={dataSelector('amount_fees', dataSelectorTarget)}
        />
        <CalculatedLabel>{feeLabel}</CalculatedLabel>
      </>
    )}
  </Wrapper>
);

Result.propTypes = {
  dataSelectorTarget: PropTypes.string,
  payoutLabel: PropTypes.string.isRequired,
  payoutSublabel: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  payout: PropTypes.string.isRequired,
  fees: PropTypes.string.isRequired,
  resultTitle: PropTypes.string.isRequired,
  feeLabel: PropTypes.string.isRequired,
  prependCurrencySymbol: PropTypes.bool.isRequired,
  hideFeeAmount: PropTypes.bool,
};

/**
 * @component
 */
export default Result;
