import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

export function getFeePercentageFromOptions(options, key) {
  return parseFloat(get('value', options[key]));
}

export function getPlanOptions(plans, selectedPlan) {
  const { options = [] } = find({ value: selectedPlan }, plans) || {};

  return options;
}
