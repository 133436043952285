// eslint-disable-next-line import/prefer-default-export
export const normalizeAmount = (value) => {
  if (!value || !value.length) {
    return value;
  }

  const matches = value.match(/[^\d](\d{1,2})$/) || [];
  const [, decimals] = matches;

  const digits = value.replace(/[^\d]/g, '');

  if (digits === '') {
    return '0.00';
  }

  const integers =
    decimals === undefined ? digits : digits.slice(0, -decimals.length);
  const numberString = `${integers}.${decimals}`;
  return parseFloat(numberString);
};
