import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CurrencyInput, Select } from '@sumup/circuit-ui';
import get from 'lodash/fp/get';

import * as FormService from './FormService';

import * as currencyAmountUtils from '~/shared/util/currency-amount-utils';
import dataSelector from '~/shared/util/data-selector';

const DEFAULT_FEE_KEY = 1;

/**
 * Form for pricing calculator
 */
const Form = ({
  plansDropdownLabel,
  plans,
  feesDropdownLabel,
  dataSelectorTarget,
  taxAmountLabel,
  onFeeChange,
  onTransactionVolumeChange,
  locale,
  currency,
}) => {
  const initialPlan = get('[0].value', plans) || '';

  const [selectedPlan, setSelectedPlan] = useState(initialPlan);
  const [selectedFeeOptionKey, setSelectedFeeOptionKey] =
    useState(DEFAULT_FEE_KEY);

  const options = FormService.getPlanOptions(plans, selectedPlan);

  const handleFeeInputChange = (event) => {
    const { value } = event.target;
    const feePercentage = FormService.getFeePercentageFromOptions(
      options,
      value - 1,
    );

    setSelectedFeeOptionKey(value);

    onFeeChange(feePercentage);
  };

  const handlePlanInputChange = (event) => {
    const { value: newSelectedPlan } = event.target;
    const newOptions = FormService.getPlanOptions(plans, newSelectedPlan);

    const feePercentage = FormService.getFeePercentageFromOptions(
      newOptions,
      DEFAULT_FEE_KEY - 1,
    );

    setSelectedPlan(newSelectedPlan);
    setSelectedFeeOptionKey(DEFAULT_FEE_KEY);

    onFeeChange(feePercentage);
  };

  const handleTransactionVolumeInputChange = (event) => {
    const { value } = event.target;
    const feePercentage = FormService.getFeePercentageFromOptions(
      options,
      selectedFeeOptionKey - 1,
    );
    const transactionVolume = currencyAmountUtils.normalizeAmount(value) || 0;

    onFeeChange(feePercentage);
    onTransactionVolumeChange(transactionVolume);
  };

  // 1. Because some values might be the same, we use a key to choose the correct option
  // 2. The key starts from 1 because a falsy value in 'circuit-ui select' shows a placeholder
  const feeOptions = options.map((option, index) => ({
    ...option,
    value: index + 1,
  }));

  const planOptions = plans.map(({ label, value }) => ({
    label,
    value,
  }));

  return (
    <form onSubmit={(e) => e.preventDefault()} noValidate>
      <Select
        label={plansDropdownLabel}
        value={selectedPlan}
        onChange={handlePlanInputChange}
        options={planOptions}
        id="selectPlan"
        name="selectPlan"
        data-selector={dataSelector('select_plan', dataSelectorTarget)}
        style={{ marginBottom: '16px' }}
      />
      <Select
        label={feesDropdownLabel}
        value={selectedFeeOptionKey}
        onChange={handleFeeInputChange}
        options={feeOptions}
        id="selectFee"
        name="selectFee"
        data-selector={dataSelector('select_fee', dataSelectorTarget)}
        style={{ marginBottom: '16px' }}
      />
      <CurrencyInput
        label={taxAmountLabel}
        locale={locale}
        currency={currency}
        onChange={handleTransactionVolumeInputChange}
        placeholder={0}
        id="txAmount"
        name="txAmount"
        data-selector={dataSelector(
          'input_transaction_volume',
          dataSelectorTarget,
        )}
      />
    </form>
  );
};

Form.propTypes = {
  plansDropdownLabel: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  feesDropdownLabel: PropTypes.string.isRequired,
  dataSelectorTarget: PropTypes.string.isRequired,
  taxAmountLabel: PropTypes.string.isRequired,
  onFeeChange: PropTypes.func.isRequired,
  onTransactionVolumeChange: PropTypes.func.isRequired,
  transactionVolume: PropTypes.number,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

/**
 * @component
 */
export default Form;
