import round from 'lodash/round';
import { formatCurrency as circuitFormatCurrency } from '@sumup/intl';

export function calculateFees(fee, transactionVolume) {
  const feeAmount = (transactionVolume * fee) / 100;

  return round(feeAmount, 2);
}

export function calculatePayout(fees, transactionVolume) {
  const payoutAmount = transactionVolume - fees;

  return transactionVolume > 0 ? round(payoutAmount, 2) : 0;
}

export function formatCurrency(number, locale, currency) {
  return circuitFormatCurrency(number, locale, currency);
}
