import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Card } from '@sumup/circuit-ui';
import { Row, Col, Grid } from '@sumup/circuit-ui/legacy';

import slugify from '../../util/slugify';

import {
  calculateFees,
  calculatePayout,
  formatCurrency,
} from './PricingCalculatorService';
import Result from './components/Result';
import Form from './components/Form';

import dataSelector from '~/shared/util/data-selector';

const DATA_SELECTOR = 'pricing_calculator';

const calculatorHeadingStyles = ({ theme }) => css`
  text-align: center;
  margin-bottom: ${theme.spacings.tera};
`;

const CalculatorHeading = styled(Headline)(calculatorHeadingStyles);

const calculatorCardStyles = ({ theme }) => css`
  padding-top: ${theme.spacings.giga};
`;

const CalculatorCard = styled(Card)(calculatorCardStyles);
/**
 * Locale can be undefined sometimes, adding default as Brazil
 * since the calculator is mainly used on this site.
 */
const PricingCalculator = ({
  title,
  plans,
  plansDropdownLabel,
  feesDropdownLabel,
  resultTitle,
  payoutLabel,
  payoutSublabel,
  feeLabel,
  currencySymbol,
  taxAmountLabel,
  locale = 'pt-BR',
  currency = 'BRL',
  prependCurrencySymbol,
  hideFeeAmount,
}) => {
  const [transactionVolume, setTransactionVolume] = useState(0);
  const [feePercentage, setFeePercentage] = useState(0);

  if (isEmpty(plans)) {
    return null;
  }

  const fees = calculateFees(feePercentage, transactionVolume);

  const payout = calculatePayout(fees, transactionVolume);

  const formattedFees = formatCurrency(fees, locale, currency);
  const formattedPayout = formatCurrency(payout, locale, currency);

  return (
    <div
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:pricing_calculator"
    >
      <Grid>
        {title && (
          <Row>
            <Col span="12">
              <CalculatorHeading id={slugify(title)} as="h2" size="one">
                {title}
              </CalculatorHeading>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={{ default: 12, mega: 10 }} skip={{ default: 0, mega: 1 }}>
            <CalculatorCard>
              <Row>
                <Col span={{ default: 12, mega: 6 }}>
                  <Form
                    plansDropdownLabel={plansDropdownLabel}
                    plans={plans}
                    feesDropdownLabel={feesDropdownLabel}
                    dataSelectorTarget={DATA_SELECTOR}
                    taxAmountLabel={taxAmountLabel}
                    transactionVolume={transactionVolume}
                    onFeeChange={setFeePercentage}
                    onTransactionVolumeChange={setTransactionVolume}
                    locale={locale}
                    currency={currency}
                  />
                </Col>
                <Col span={{ default: 12, mega: 6 }}>
                  <Result
                    dataSelectorTarget={DATA_SELECTOR}
                    payoutLabel={payoutLabel}
                    payoutSublabel={payoutSublabel}
                    currencySymbol={currencySymbol}
                    payout={formattedPayout}
                    fees={formattedFees}
                    resultTitle={resultTitle}
                    feeLabel={feeLabel}
                    prependCurrencySymbol={prependCurrencySymbol}
                    hideFeeAmount={hideFeeAmount}
                  />
                </Col>
              </Row>
            </CalculatorCard>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

PricingCalculator.propTypes = {
  title: PropTypes.string,
  plans: PropTypes.array,
  plansDropdownLabel: PropTypes.string,
  feesDropdownLabel: PropTypes.string,
  resultTitle: PropTypes.string,
  payoutLabel: PropTypes.string,
  payoutSublabel: PropTypes.string,
  feeLabel: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  taxAmountLabel: PropTypes.string,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  prependCurrencySymbol: PropTypes.bool,
  hideFeeAmount: PropTypes.bool,
};

/**
 * @component
 */
export default PricingCalculator;
